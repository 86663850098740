<template>
    <div class="communication-feedback">
        <StackRouterHeaderBar />

        <main class="main">
            <FeedbackTop
                :title="'왜 연락을<br />그만하게 되었나요?'"
                :description="'상대방과 주선자에게 공개되지 않아요'"
            />
            <FeedbackOptionItems v-if="items.length > 0" v-model="items" :multiSelect="true" />
            <transition name="slide-down">
                <div v-if="showDetailReason" class="detail-reason m-b-12">
                    <p class="desc m-b-8">기타 사유를 직접 입력해주세요</p>
                    <TextareaWithX :placeholder="'내용을 입력해주세요'" v-model="detailReason" />
                </div>
            </transition>
            <FeedbackDescription class="m-b-40" />
        </main>

        <footer class="footer">
            <button class="btn btn-primary" v-html="$translate('NEXT')" :disabled="disabled" @click="onClickNext" />
        </footer>
    </div>
</template>

<script>
import FeedbackTop from './components/FeedbackTopComponent'
import FeedbackDescription from './components/FeedbackDescription'
import FeedbackOptionItems from './components/FeedbackOptionItems'

export default {
    name: 'CommunicationFeedbackPage',
    components: { FeedbackTop, FeedbackDescription, FeedbackOptionItems },
    data: () => ({
        detailReason: '',
        items: [],
    }),
    computed: {
        disabled() {
            return this.items.filter(item => item.selected).length === 0
        },
        showDetailReason() {
            const etc = this.items.find(item => item.title === '기타')

            if (!etc) return

            return etc.selected
        },
    },
    methods: {
        async init() {
            const { paid } = this.$store.getters.feedbackDating
            const options = await this.$store.dispatch(
                'filterFeedbackOptionByCategory',
                `stop_reason_${paid ? 'paid' : 'non_paid'}`
            )

            this.items = options.map(option => {
                this.$set(option, 'selected', false)
                return option
            })
        },
        onClickNext() {
            const payload = this.preparedPayload()
            this.$store.commit('setSelectedFeedbacks', { category: 'stopReason', feedbacks: payload })
            this.$stackRouter.push({ name: 'RecommendablePersonPage' })
        },
        preparedPayload() {
            return this.items
                .filter(item => item.selected)
                .map(item => {
                    if (item.title === '기타') {
                        return { id: item.id, detail: this.detailReason }
                    } else {
                        return { id: item.id }
                    }
                })
        },
    },
    mounted() {
        this.init()
    },
}
</script>

<style lang="scss" scoped>
.communication-feedback {
    .main {
        height: calc(100% - 56px - 80px);
        overflow-y: auto;
        padding: 0 16px;
    }

    .items {
        margin-bottom: 24px;

        .item {
            padding: 11px 16px;
            border-radius: 8px;
            border: solid 1px $grey-02;

            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
    }

    .detail-reason {
        .desc {
            font-size: 12px;
            color: $grey-06;
            font-weight: 500;

            @include f-medium;
        }

        .textarea-with-x {
            height: 160px;
        }
    }

    .footer {
        position: absolute;
        bottom: 0;
        padding: 16px 20px;
        width: 100vw;
        border-top: solid 1px $grey-02;

        .btn {
            width: 100%;
            height: 48px;
        }
    }
}
</style>
